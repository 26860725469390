import axios from 'axios';
import config from '../config/config';
import {FormField} from '../interface/form';

const {host} = config.webuildbots;
const baseUrl = `${host}/api/extension`;

export default class WebuildbotsApi {
  protected readonly defaultParams: {token: string; client: string};

  constructor(token: string, client: string, private channel: string) {
    this.defaultParams = {
      token,
      client,
    };
  }

  public async closeExtension(blockId: string): Promise<void> {
    // Close the extension
    await axios({
      method: 'post',
      url: `${baseUrl}/close-extension`,
      data: {
        ...this.defaultParams,
        blockId,
      },
    });

    if (this.channel === 'messenger') {
      const w = window as any;
      w.MessengerExtensions.requestCloseBrowser();
    }
  }

  public async setFormFields(
    fields: FormField[],
    formId: string,
  ): Promise<any> {
    return axios({
      method: 'put',
      url: `${baseUrl}/form/set-fields?formId=${formId}`,
      data: {
        ...this.defaultParams,
        fields,
      },
    });
  }
}
