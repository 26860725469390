export enum CANCEL_VARIATIONS {
    ANY = "Cancel",
    AF = "Kanselleer",
    SQ = "Anuloj",
    AM = "ሰርዝ",
    AR = "إلغاء",
    HY = "Չեղարկել",
    AS = "বাতিল কৰক",
    AY = "Janq'asiña",
    AZ = "Ləğv et",
    BM = "Fage",
    EU = "Utzi",
    BE = "Адмяніць",
    BN = "বাতিল করুন",
    BHO = "रद्द करें",
    BS = "Otkaži",
    BG = "Отказ",
    CA = "Cancel·la",
    CEB = "Ikansela",
    "ZH-CN" = "取消",
    "ZH-TW" = "取消",
    CO = "Annulla",
    HR = "Otkazati",
    CS = "Zrušit",
    DA = "Afbestil",
    DV = "ހުއްދަކުރޭ",
    DOI = "ਰੱਦ ਕਰੋ",
    NL = "Annuleren",
    EN = "Cancel",
    EO = "Nuligi",
    ET = "Tühista",
    EE = "Fɔgbɔ",
    FIL = "Kanselahin",
    FI = "Peruuta",
    FR = "Annuler",
    FY = "Ofbestelle",
    GL = "Cancelar",
    KA = "გაუქმება",
    DE = "Abbrechen",
    EL = "Ακύρωση",
    GN = "Ko'ãga",
    GU = "રદ કરો",
    HT = "Anile",
    HA = "Soke",
    HAW = "Hoʻopau",
    HE = "ביטול",
    HI = "रद्द करें",
    HMN = "Tso cai",
    HU = "Mégsem",
    IS = "Hætta við",
    IG = "Kagbuo",
    ILO = "Ibabawi",
    ID = "Batal",
    GA = "Cealaigh",
    IT = "Annulla",
    JA = "キャンセル",
    JV = "Batal",
    KN = "ರದ್ದುಮಾಡಿ",
    KK = "Болдырмау",
    KM = "បោះបង់",
    RW = "Emeza",
    GOM = "रद्द कर",
    KO = "취소",
    KRI = "Cancel",
    KU = "Betal bike",
    CKB = "به‌تاڵكردنه‌وه‌",
    KY = "Жокко чыгаруу",
    LO = "ຍົກເລີກ",
    LA = "Annullare",
    LV = "Atcelt",
    LN = "Kobundisa",
    LT = "Atšaukti",
    LG = "Kozzawo",
    LB = "Ofbriechen",
    MK = "Откажи",
    MAI = "रद्द करू",
    MG = "Manakana",
    MS = "Batal",
    ML = "റദ്ദാക്കുക",
    MT = "Ikkanċella",
    MI = "Whakakore",
    MR = "रद्द करा",
    "MNI-MTEI" = "বাতিল করেক",
    LUS = "Cancel rawh",
    MN = "Цуцлах",
    MY = "ဖျက်သိမ်း",
    NE = "रद्द गर्नुहोस्",
    NO = "Avbryt",
    NY = "Kuchotsa",
    OR = "ନାକଚ",
    OM = "Baqqaa",
    PS = "لغوه",
    FA = "لغو",
    PL = "Anuluj",
    PT = "Cancelar",
    PA = "ਰੱਦ ਕਰੋ",
    QU = "Ch'usaq",
    RO = "Anulează",
    RU = "Отменить",
    SM = "Faaleagaga",
    SA = "परित्यज",
    GD = "Sguir",
    NSO = "Khansele",
    SR = "Откажи",
    ST = "Khansela",
    SN = "Bvisa",
    SD = "منسوخ ڪريو",
    SI = "අවලංගු කරන්න",
    SK = "Zrušiť",
    SL = "Prekliči",
    SO = "Ka noqo",
    ES = "Cancelar",
    SU = "Batalkeun",
    SW = "Ghairi",
    SV = "Avbryt",
    TL = "Kanselahin",
    TG = "Бекор кунед",
    TA = "ரத்து செய்",
    TT = "Баш тарту",
    TE = "రద్దుచేయండి",
    TH = "ยกเลิก",
    TI = "ትዕግስት",
    TS = "Khansela",
    TR = "İptal et",
    TK = "Ýatyrmak",
    AK = "Twa mu",
    UK = "Скасувати",
    UR = "منسوخ کریں",
    UG = "بىكار قىلىش",
    UZ = "Bekor qilish",
    VI = "Hủy bỏ",
    CY = "Diddymu",
    XH = "Rhoxisa",
    YI = "באָטל מאַכן",
    YO = "Fagilee",
    ZU = "Khansela",
}

export enum SUBMIT_VARIATIONS {
    ANY = "SUBMIT",
    AF = "Dien in",
    SQ = "Paraqit",
    AM = "አስገባ",
    AR = "إرسال",
    HY = "Ուղարկել",
    AS = "জমা দিওক",
    AY = "Apuña",
    AZ = "Göndər",
    BM = "Nafage",
    EU = "Bidali",
    BE = "Адправіць",
    BN = "জমা দিন",
    BHO = "प्रस्तुत करें",
    BS = "Pošalji",
    BG = "Изпращане",
    CA = "Envia",
    CEB = "Isumite",
    "ZH-CN" = "提交",
    "ZH-TW" = "提交",
    CO = "Invia",
    HR = "Pošalji",
    CS = "Odeslat",
    DA = "Indsend",
    DV = "ތެރެއިހަމަ",
    DOI = "ਪੇਸ਼ ਕਰੋ",
    NL = "Verzenden",
    EN = "Submit",
    EO = "Sendi",
    ET = "Esita",
    EE = "Gɔme",
    FIL = "Isumite",
    FI = "Lähetä",
    FR = "Soumettre",
    FY = "Yntsjinne",
    GL = "Enviar",
    KA = "წარუმოიყენე",
    DE = "Einreichen",
    EL = "Υποβολή",
    GN = "Emboje",
    GU = "સબમિટ કરો",
    HT = "Soumèt",
    HA = "Aika",
    HAW = "Hoʻouna",
    HE = "שלח",
    HI = "प्रस्तुत करें",
    HMN = "Tso",
    HU = "Beküld",
    IS = "Senda inn",
    IG = "Tinye",
    ILO = "Isumite",
    ID = "Kirim",
    GA = "Cuir isteach",
    IT = "Invia",
    JA = "提出する",
    JV = "Kirim",
    KN = "ಸಲ್ಲಿಸಿ",
    KK = "Жіберу",
    KM = "ដាក់ស្នើ",
    RW = "Reba",
    GOM = "प्रस्ताव दिवे",
    KO = "제출",
    KRI = "Submit",
    KU = "Pêşkeş bike",
    CKB = "ناردن",
    KY = "Жөнөтүү",
    LO = "ສົ່ງເປັນ",
    LA = "Submittere",
    LV = "Iesniegt",
    LN = "Lobisa",
    LT = "Pateikti",
    LG = "Okutondera",
    LB = "Ofschécken",
    MK = "Поднеси",
    MAI = "प्रस्तुत करू",
    MG = "Alefaso",
    MS = "Hantar",
    ML = "സമർപ്പിക്കുക",
    MT = "Ippreżenta",
    MI = "Tuku",
    MR = "सबमिट करा",
    "MNI-MTEI" = "পেশ করেক",
    LUS = "Theh lut rawh",
    MN = "Илгээх",
    MY = "တင်သွင်းပါ",
    NE = "पेस गर्नुहोस्",
    NO = "Send inn",
    NY = "Lembetsani",
    OR = "ଦାଖଲ କରନ୍ତୁ",
    OM = "Koofadhu",
    PS = "وسپارل",
    FA = "ارسال",
    PL = "Zatwierdź",
    PT = "Enviar",
    PA = "ਜਮ੍ਹਾਂ ਕਰੋ",
    QU = "Kachariy",
    RO = "Trimite",
    RU = "Отправить",
    SM = "Tu'u",
    SA = "प्रस्तुत्यताम्",
    GD = "Cuir a-steach",
    NSO = "Romela",
    SR = "Поднеси",
    ST = "Romela",
    SN = "Tumira",
    SD = "پيش ڪريو",
    SI = "ඉදිරිපත් කරන්න",
    SK = "Odoslať",
    SL = "Pošlji",
    SO = "Dir",
    ES = "Enviar",
    SU = "Kirim",
    SW = "Wasilisha",
    SV = "Skicka",
    TL = "Isumite",
    TG = "Ирсол кунед",
    TA = "சமர்ப்பிக்கவும்",
    TT = "Йибәрү",
    TE = "సబ్మిట్ చేయండి",
    TH = "ส่ง",
    TI = "ኣስገባ",
    TS = "Roma",
    TR = "Gönder",
    TK = "Iber",
    AK = "Dua",
    UK = "Надіслати",
    UR = "جمع کرائیں",
    UG = "تاپشۇرۇڭ",
    UZ = "Yuborish",
    VI = "Gửi",
    CY = "Cyflwyno",
    XH = "Thumela",
    YI = "אײַנרײַכן",
    YO = "Fi silẹ",
    ZU = "Thumela"
}
