import React from 'react';
import {Field} from 'formik';
import styled from 'styled-components';
import {FieldProps} from 'src/interface/form';

const TextField: React.FC<FieldProps> = ({
  name,
  type,
  label,
  errors,
  touched,
}) => {
  return (
    <Field name={name}>
      {({field}) => (
        <div>
          <Label>{label}</Label>
          <Input
            errors={errors}
            touched={touched}
            {...field}
            type={type}
            autoComplete={'off'}
          />
          {errors[name] && touched[name] ? <P>{errors[name]}</P> : null}
        </div>
      )}
    </Field>
  );
};

const P = styled.p`
  color: #e82959;
  font-size: 0.8em;
  margin: -10px 0 15px 0;
  display: block;
`;

const Input = styled.input<FieldProps>`
  width: 100%;
  display: block;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 0 15px;
  line-height: 30px;
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 15px;
  &:focus {
    border: 1px dotted #0084ff;
  }
  border: ${({errors, name}) =>
    errors[name] ? '1px solid #e82959' : '1px solid #e9e9e9'};
  &:focus {
    outline: 1px dotted #00aeef;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 0.9em;
  margin-bottom: 5px;
  font-weight: bold;
  min-height: 13px;
`;

export default TextField;
