import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import './normalize.css';
import FormExtension from './components/form/FormExtension';

function App() {
  // Import the messenger extension sdk can we assessed though {code (window as any).MessengerExtensions}
  // typically you should use {@code WebuildbotsApi}
  useEffect(() => {
    const js = document.createElement('script');
    js.id = 'Messenger';
    js.src = '//connect.facebook.net/en_US/messenger.Extensions.js';
    document.head.appendChild(js);
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/core/form" component={FormExtension} />
      </Switch>
    </div>
  );
}

export default App;
