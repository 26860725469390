import Config from '../interface/config';
import {Environment} from '../const';

const config: Config = {
  environment: Environment.PRODUCTION,
  webuildbots: {
    host: 'https://backend.logicdialog.ai',
  },
};

export default config;
