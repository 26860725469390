import React from 'react';
import styled from 'styled-components';
import {getQueryParams} from '../../util/query-string';

interface Props {
  value: string;
  type: string;
  onClick?: () => void;
}

interface StyledProps {
  colour: string;
}

const FormButton: React.FC<Props> = ({value, type, onClick}) => {
  const {primaryColour} = getQueryParams();
  return (
    <Input colour={primaryColour} type={type} onClick={onClick} value={value} />
  );
};

const Input = styled.input<StyledProps>`
  display: flex;
  flex-grow: 0;
  margin: 0 5px 10px 0;
  border-radius: 100px;
  font-size: 14px;
  border: 1px solid #${(props) => props.colour};
  border-color: inherit !important;
  color: inherit !important;
  text-align: center;
  padding: 8px 8px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  width: auto;
  align-items: flex-end;
  background-color: #fff;

  &:hover {
    color: #ffffff;
    background: #${(props) => props.colour};
  }
`;

export default FormButton;
