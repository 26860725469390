import {FormikErrors, FormikTouched} from 'formik';
import {Languages} from '@webuildbots/webuildbots-sdk/lib/const/languages-enums';
export interface FormField {
  _id: string;
  title: string;
  key: string;
  secret?: boolean;
  value?: any;
  validations: ValidationField[];
  optional?: boolean;
  fieldType?: FormFieldTypes;
  dataType?: FormFieldDataTypes;
  choices?: Choice[];
  question?: {[key in Languages]?: string;};
  language?: Languages;
}

export interface ValidationField {
  _id?: any;
  created: Date;
  title: string;
  regex: string;
  failureBlockId: string;
  failureText: {
    any?: string;
    en: string;
    nl?: string;
    cy?: string;
    it?: string;
    de?: string;
  };
}

export default interface Choice {
  title: {
    any?: string;
    en: string;
    nl?: string;
    cy?: string;
    it?: string;
    de?: string;
  };
  selectedTitle?: string;
  action: {
    type: string;
    value?: any;
  };
  profile?: any;
  formValue: string;
  userStatus?: string;
  session?: any;
  functionArgs?: any;
  persistentMenu?: boolean;
  next?: string;
  selectBlock?: {
    id: string;
  };
  _id?: any;
}

export enum FormFieldTypes {
  // User supplies text
  INPUT = 'input',
  // User selects from buttons
  SELECT = 'select',
  // User has buttons offered to them but can also enter text
  OPEN_SELECT = 'open-select',
  JSON_SELECT = 'json-select',
  // User is offered buttons with frequently used dates, or a date of their choosing can be entered as text.
  OPEN_SELECT_DATE = 'open-select-date',
  INPUT_DATE = 'input-date',
  INPUT_EMAIL = 'input-email',
}

export enum FormFieldDataTypes {
  STRING = 'string',
  NUMBER = 'number',
  JSON = 'json',
  DATE = 'date',
  BOOLEAN = 'boolean',
  IMAGE = 'image',
}

export enum FieldTypes {
  TEXT = 'text',
  SELECT = 'select',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
  IMAGE = 'image',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface FieldProps {
  name: string;
  type: string;
  label?: string;
  choices?: Choice[];
  errors: FormikErrors<Record<string, any>>;
  touched: FormikTouched<Record<string, any>>;
}
