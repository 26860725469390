import React from 'react';
import {Field} from 'formik';
import styled from 'styled-components';
import {FieldProps} from 'src/interface/form';

const SelectField: React.FC<FieldProps> = ({
  name,
  type,
  label,
  choices,
  errors,
  touched,
}) => {
  return (
    <Field name={name}>
      {({field}) => (
        <div>
          <Label>{label}</Label>
          <Select
            errors={errors}
            touched={touched}
            {...field}
            type={type}
            autoComplete={'off'}
          >
            <option value=""> - Select an option - </option>
            {choices &&
              choices.map(({title, formValue}) => (
                <option value={formValue}>${title.en}</option>
              ))}
          </Select>
          {errors[name] && touched[name] ? <P>{errors[name]}</P> : null}
        </div>
      )}
    </Field>
  );
};

const P = styled.p`
  color: #e82959;
  font-size: 0.8em;
  margin: -10px 0 15px 0;
  display: block;
`;

const Select = styled.select<FieldProps>`
  width: 100%;
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  margin-bottom: 15px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  &:focus {
    border: 1px dotted #0084ff;
  }
  border: ${({errors, name}) =>
    errors[name] ? '1px solid #e82959' : '1px solid #e9e9e9'};
  &:focus {
    outline: 1px dotted #00aeef;
  }

  option {
    background: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 0.9em;
  margin-bottom: 5px;
  font-weight: bold;
  min-height: 13px;
`;

export default SelectField;
